.TextInputWrapper {
  display: grid;
  grid-template-rows: 1rem 1fr;
  grid-gap: 0.5rem;
}

.TextInputLabel {
  padding: 0 1rem;
  text-transform: capitalize;
  text-align: left;
}

.TextInput {
  background: #f9f8f8;
  border: 1px solid #ccc;
  padding: 1rem;
  width: 100%;
  text-transform: capitalize;
}

.TextInputNotValid {
  border-color: #f00;
}

.TextInput::placeholder {
  text-transform: capitalize;
}
