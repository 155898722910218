body {
  margin: 0;
  background: #fff;
  color: #000;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1rem;
}

a {
  text-decoration: underline;
  color: #ff5b5f;
}

a:hover {
  text-decoration: none;
}

textarea,
input,
select,
button {
  font-family: inherit;
  font-size: inherit;
  border-radius: 0;
  border: 0;
  margin: 0;
}

textarea:focus,
input:focus,
select:focus,
button:focus {
  outline: none;
}

img {
  display: block;
}

input[type="text"],
input[type="number"],
input[type="search"],
input[type="email"],
input[type="tel"],
input[type="password"],
textarea {
  box-sizing: border-box;

  /* remove inner shadows */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* hide arrows from number input */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
