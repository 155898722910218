.PrimaryButton {
  text-transform: capitalize;
  background: #ff5b5f;
  border: 2px solid #ff5b5f;
  height: 3.25rem;
  font-weight: 600;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  transition: all 500ms ease;
  position: relative;
  cursor: pointer;
}

.PrimaryButton:hover {
  background: none;
  color: #ff5b5f;
}

.PrimaryButton .Spinner {
  width: 1rem;
  height: 1rem;
  top: calc(50% - 0.5rem - 2px);
  left: calc(50% - 0.5rem - 2px);
}
