.Timeline {
  flex: 1;
  background: #f9f9f9;
  position: relative;
  overflow: scroll;
  padding: 1rem 1rem 0;
}

.TimelineYAxis {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 7rem;
  border-left: 1px solid #ddd;
  z-index: 1;
}

.TimelineWrapper {
  border-top: 1px solid #ddd;
  height: 3rem;
  position: relative;
}

.TimelineTime {
  position: absolute;
  top: -0.5rem;
  text-transform: uppercase;
  width: 2.5rem;
  text-align: right;
  font-size: 0.8rem;
  background: #f9f9f9;
  padding-right: 3px;
}

@media only screen and (max-width: 320px), screen and (max-height: 734px) {
  .TimelineYAxis {
    left: 5rem;
  }
}
