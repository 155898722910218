.BookingDialog {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  left: 100%;
  transition: all 500ms ease;
}

.ShowBookingDialog {
  left: 0;
}

.BookingDialogWrapper {
  background: #fff;
  box-sizing: border-box;
  display: grid;
  width: 50%;
  overflow: hidden;
}

.BookingDialogWrapper > div {
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 2rem;
}

.BookingDialogDetails {
  font-weight: 600;
  font-size: 2rem;
  position: relative;
}

.BookingDialogHeader {
  background: #f3f3f3;
  flex: 1;
  font-size: 1.5rem;
}

.BookingDialogMain {
  background: #f3f3f3;
  gap: 5px;
}

.BookingDialogMainName {
  text-transform: capitalize;
}

.BookingDialogMain .TextInputWrapper {
  flex: 1;
}

@media only screen and (max-width: 640px) {
  .BookingDialogWrapper {
    width: calc(100% - 2rem);
  }

  .BookingDialogWrapper > div {
    padding: 1rem !important;
  }

  .BookingDialogMain {
    flex-direction: column;
    gap: 1rem;
  }
}
