.Seat {
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  background: #e8e6fa;
  border: 1px dashed #e8e6fa;
}

.SeatNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #4635cd;
}

.SeatIcon {
  display: flex;
  justify-content: center;
  fill: #4635cd;
}

.SeatIcon svg {
  width: 1.5rem;
  height: auto;
}

.AvailableSeat:hover {
  border-color: #4635cd;
}

.NotAvailableSeat {
  background: #e1e1e1;
  border-color: #e1e1e1;
  cursor: default;
}

.NotAvailableSeat .SeatIcon svg {
  fill: #ccc;
}

.NotAvailableSeat .SeatNumber {
  color: #ccc;
}

.ReservedSeat {
  background: #e1e1e1;
  border-color: #e1e1e1;
  cursor: default;
}

.ReservedSeat .SeatIcon svg {
  fill: #ccc;
}

.ReservedSeat .SeatNumber {
  color: #ccc;
}

.ReservedSeat:hover {
  border-color: #e1e1e1;
}

@media only screen and (max-width: 640px) {
  .Seat {
    padding: 0;
  }
}
