.Releases {
  background: #f1f1f1;
  padding: 3rem;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 2rem;
}

.ReleasesHeaderTitle {
  font-size: 3rem;
  font-weight: 200;
}

.ReleasesHeaderSubtitle {
  color: #999;
}

.ReleasesMain {
  display: grid;
  gap: 2rem;
  grid-auto-rows: max-content;
  overflow: scroll;
}

.Release {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 1rem;
}

.ReleaseHeader {
  display: grid;
}

.ReleaseVersion {
  font-size: 1.5rem;
  text-transform: capitalize;
}

.ReleaseDate {
  font-size: 1.5rem;
  text-transform: capitalize;
  color: #999;
}

.ReleaseMain {
  display: grid;
  gap: 1rem;
  grid-auto-rows: max-content;
}

.ReleaseNote {
  display: grid;
}

.ReleaseParagraph {
  width: 50%;
}

@media only screen and (max-width: 640px) {
  .Releases {
    padding: 1rem;
  }

  .ReleasesHeaderTitle {
    font-size: 2rem;
  }

  .ReleaseParagraph {
    width: auto;
  }

  .ReleaseVersion,
  .ReleaseDate {
    font-size: 1.2rem;
  }
}
