.DashboardWrapper {
  flex: 1;
  padding: 0 3rem 1rem;
  display: flex;
  overflow: hidden;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .DashboardWrapper {
    padding: 0 1rem 1rem;
  }
}

@media print {
  .DashboardWrapper {
    padding: 0;
  }
}
