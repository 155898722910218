.Calendar {
  margin: 0;
  padding: 0;
  border: 2px solid #000;
  background: #fff;
  position: absolute;
  left: -2px;
  width: 100%;
  top: calc(100% + 3px);
}

.CalendarHeader {
  display: grid;
  grid-template-columns: 3rem 1fr 3rem;
  background: #fce1e1;
  border-bottom: 2px solid #000;
  height: 2rem;
}

.CalendarNavIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.CalendarNavIconWrapper:hover {
  background: #fff;
}

.CalendarNavIcon {
  width: 1.5rem;
  height: auto;
}

.CalendarNavIconWrapper:hover .CalendarNavIcon {
  fill: #faa3a3;
}

.CalendarMonth {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border-left: 2px solid #000;
  border-right: 2px solid #000;
}

.CalendarWeekDays {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.CalendarWeekDay {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 5px 0;
}

.CalendarDates {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.CalendarDate {
  display: flex;
  justify-content: center;
  padding: 5px 0;
  border-top: 1px solid #555;
}

.CalendarDate:hover {
  background: #fce1e1;
}

.Sunday {
  background: #d9faea;
  color: #09c466;
}

.Sunday:hover {
  background: #1de883;
  color: #f1fff8;
}

.Saturday {
  background: #cee6fc;
  color: #1d86e8;
}

.Saturday:hover {
  background: #71b8fa;
  color: #cee6fc;
}

.PastDate {
  color: #ddd;
  background: unset;
}

.CalendarDateInvalid {
  border: unset;
  padding: 0;
}

.CalendarDateInvalid:hover,
.PastDate:hover {
  background: unset;
  color: #ddd;
}

.CalendarDateToday {
  color: #f00;
}

@media only screen and (max-width: 640px) {
  .Calendar {
    left: 1rem;
    right: 1rem;
    top: calc(100% - (1rem - 1px));
    width: auto;
  }

  .CalendarHeader {
    height: auto;
  }

  .CalendarHeader > div {
    padding: 10px 0;
  }

  .CalendarWeekDay,
  .CalendarDate {
    padding: 10px 0;
  }

  .CalendarDateInvalid {
    padding: 0;
  }

  .CalendarDate:hover {
    background: unset;
  }

  .Saturday:hover {
    background: #cee6fc;
    color: #1d86e8;
  }

  .CalendarDateToday:hover {
    background: #fce1e1;
  }
}
