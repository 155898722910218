.Layout {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 1rem;
  overflow: scroll;
}

@media only screen and (max-width: 640px) {
  .Layout {
    grid-gap: 5px;
  }
}

@media only screen and (max-width: 320px), screen and (max-height: 734px) {
  .Layout {
    grid-gap: 3px;
  }
}
