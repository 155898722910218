.Profile {
  padding: 3rem 3rem 1rem;
  display: grid;
  gap: 3rem;
  grid-template-rows: auto 1fr auto;
}

.ProfileHeader {
  font-size: 1.5rem;
}

.ProfileMain {
  display: grid;
  grid-auto-rows: max-content;
  gap: 2rem;
  width: 50%;
}

.UserName {
  text-transform: capitalize;
  color: #000;
}

.ProfileFooter {
  display: flex;
}

.Logout {
  padding: 0.5rem 1rem;
  background: #fff;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  color: #999;
  transition: all 0.5s ease;
}

.Logout:hover {
  color: #000;
}

@media only screen and (max-width: 640px) {
  .Profile {
    padding: 1rem;
    overflow: scroll;
  }

  .ProfileMain {
    width: auto;
    gap: 1rem;
  }

  .Profile .PrimaryButton {
    justify-self: center;
  }

  .ProfileFooter {
    justify-content: center;
  }
}
