.Toolbar {
  padding: 1rem 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 3;
}

.ToolbarBackWrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  text-transform: capitalize;
  cursor: pointer;
  font-size: 1.3rem;
}

.ToolbarBackWrapper svg {
  fill: #ff5b5f;
  transition: all 500ms ease;
}

.ToolbarBackWrapper:hover svg {
  fill: #ccc;
}

@media only screen and (max-width: 640px) {
  .Toolbar {
    padding: 1rem;
  }
}

@media print {
  .Toolbar {
    padding: 0;
  }

  .Toolbar {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .Toolbar .SelectWrapper {
    width: auto;
    border: 0;
  }

  .Toolbar .SelectWrapper .SelectValue {
    padding: 0;
    font-weight: 600;
  }

  .Toolbar .DateWrapper {
    width: auto;
    border: 0;
  }

  .Toolbar .DateWrapper::after {
    content: " - ";
    white-space: pre;
  }

  .Toolbar .DateWrapper .DateValue {
    padding: 0;
  }

  .Toolbar .SelectWrapper .SelectValue svg,
  .Toolbar .DateWrapper .DateValue svg {
    display: none;
  }
}
