.DateWrapper {
  display: flex;
  align-items: center;
  width: 15rem;
  border: 2px solid #000;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
}

.CalendarIcon {
  fill: #000;
}

.DateWrapper:hover .CalendarIcon {
  fill: #ff5b5f;
}

.DateValue {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  text-transform: uppercase;
  color: #000;
}

.CalendarIcon {
  width: 1.5rem;
  height: auto;
}

@media only screen and (max-width: 640px) {
  .DateWrapper {
    position: static;
    width: 100%;
  }
}
