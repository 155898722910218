.Event {
  position: absolute;
  left: 3.5rem;
  right: 0;
  z-index: 2;
  background: #faa3a3;
  padding: 1rem;
  font-size: 0.9rem;
  box-sizing: border-box;
  color: #000;
  text-decoration: none;
  display: grid;
  gap: 0.5rem;
  grid-auto-rows: max-content;
}

.Event:hover {
  background: #ff8b8b;
}
