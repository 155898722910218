.App {
  display: flex;
  height: 100vh;
  overflow: hidden;
  position: relative;
  flex-direction: column;
}

header {
  padding: 1rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Logo {
  display: grid;
  grid-auto-flow: column;
  gap: 0.5rem;
  align-items: center;
  color: #4635cd;
  font-weight: 600;
  text-decoration: none;
  transition: color 0.5s ease;
}

.Logo svg {
  width: 1.5rem;
  height: auto;
  fill: #4635cd;
  transition: fill 0.5s ease;
}

.Logo:hover {
  color: #ff5b5f;
}

.Logo:hover svg {
  fill: #ff5b5f;
}

nav {
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: column;
  align-items: center;
}

nav .Link {
  text-transform: capitalize;
  color: #4635cd;
}

.ProfileIcon {
  text-decoration: none;
  display: flex;
}

.ProfileIcon svg {
  fill: #4635cd;
  transition: all 0.5s ease;
}

.ProfileIcon:hover svg {
  fill: #ff5b5f;
}

.UserName {
  text-transform: capitalize;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.View {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background: #f1f1f1;
}

footer {
  padding: 1rem 3rem;
  text-align: center;
  font-size: 0.8rem;
}

@media only screen and (max-width: 640px) {
  header,
  footer {
    padding: 1rem;
  }
}

@media print {
  header {
    background: unset;
    margin: 1rem 0;
  }

  nav {
    display: none;
  }

  header {
    padding: 0;
  }
}
