.ReleaseType {
  padding: 5px;
  color: #fff;
  font-size: 0.8rem;
  text-transform: capitalize;
  border-radius: 1rem;
  width: 3rem;
  text-align: center;
}

.ReleaseTypeFeature {
  background: #0c6;
}

.ReleaseTypeTweak {
  background: #06f;
}

.ReleaseTypeFix {
  background: #f90;
}
