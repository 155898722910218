.Terms {
  background: #f1f1f1;
  padding: 3rem;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 2rem;
}

.TermsHeader {
  display: grid;
  gap: 5px;
}

.TermsTitle {
  font-size: 3rem;
  font-weight: 200;
}

.TermsSubtitle {
  color: #999;
}

.TermsMain {
  display: grid;
  gap: 1rem;
  grid-auto-rows: max-content;
  overflow: scroll;
}

.Term {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 0.5rem;
}

.TermTitle {
  text-decoration: underline;
}

.TermBody {
  width: 50%;
}

@media only screen and (max-width: 640px) {
  .Terms {
    padding: 1rem;
  }

  .TermsTitle {
    font-size: 2rem;
  }

  .TermBody {
    width: auto;
  }
}
