.Spinner {
  border: 2px solid #f4dbdb;
  border-top: 2px solid #faa3a3;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  position: absolute;
  top: calc(50% - 1rem - 2px);
  left: calc(50% - 1rem - 2px);
  animation: spin 600ms linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
